import React from "react";
import PropTypes from "prop-types";
import * as theme1Styles from "../scss/theme1.module.scss";

const Welcome = ({ title, text, logo }) => {
  return (
    <div className={`${theme1Styles.welcomeBox}`}>
      {logo && (
        <div className={theme1Styles.icon}>
          <img src={logo} />
        </div>
      )}
      {(title || text) && (
        <div className={theme1Styles.details}>
          <h3>{title}</h3>
          <p>{text}</p>
        </div>
      )}
    </div>
  );
};

Welcome.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  logo: PropTypes.string,
};

export default Welcome;
