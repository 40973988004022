import React from "react";
import PropTypes from "prop-types";
import * as theme2Styles from "../scss/theme2.module.scss";

const Welcome = ({ title, text, logo }) => {
  return (
    <div className={`${theme2Styles.welcomeBox}`}>
      {logo && (
        <div className={`${theme2Styles.icon}`}>
          <img src={logo} />
        </div>
      )}
      {(title || text) && (
        <div className={theme2Styles.details}>
          <h3>{title}</h3>
          <p>{text}</p>
        </div>
      )}
    </div>
  );
};

Welcome.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  logo: PropTypes.string,
};

export default Welcome;
