import React from "react";
import PropTypes from "prop-types";

import * as theme4Styles from "../scss/theme4.module.scss";

// import icon from "../images/rent-home.svg";

const Welcome = ({ title, text, logo }) => {
  return (
    <div className={theme4Styles.welcomeBox}>
      {logo && (
        <div className={theme4Styles.icon}>
          <img src={logo} />
        </div>
      )}
      {(title || text) && (
        <div className={theme4Styles.details}>
          <h3>{title}</h3>
          <p>{text}</p>
        </div>
      )}
    </div>
  );
};

Welcome.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  logo: PropTypes.string,
};

export default Welcome;
