import React, { useContext } from "react";
import { navigate } from "gatsby";

import { useInput } from "../../../hooks/input-hook";
import SiteMetaContext from "../../../contexts/SiteMetaContext";
import {
  theme4Vars,
  templateVariableValue,
} from "../../../helpers/templateVariables";
import { translateStatic } from "../../../helpers/translationHelper";
import * as theme4Styles from "../scss/theme4.module.scss";

import searchIcon from "../images/search-bold.png";

const Banner = () => {
  // eslint-disable-next-line no-unused-vars
  const { value: keyword, bind: bindKeyword, reset: resetKeyword } = useInput(
    ""
  );

  const siteData = useContext(SiteMetaContext);
  const {
    remarso: {
      domainByURL: {
        website: {
          setting: display_settings,
          templateConfiguration,
          template: { templateVariables },
        },
      },
    },
    currentLocaleKey: locale,
    defaultLocaleKey: defaultLocale,
  } = siteData;

  const handleSubmit = (evt) => {
    evt.preventDefault();
    navigate(`/${locale}/properties?search=${keyword}`);
  };

  const { display_search_bar } = display_settings;

  const mainTitle = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-main-title",
    locale,
    defaultLocale
  );
  const backgroundImg = templateVariableValue(
    templateConfiguration,
    templateVariables,
    theme4Vars[0].slug,
    locale,
    defaultLocale
  );
  let placeholder = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-search-placeholder",
    locale,
    defaultLocale
  );
  placeholder = placeholder || translateStatic("banner_search_text", locale, defaultLocale);

  return (
    <section
      className={theme4Styles.banner}
      style={{
        backgroundImage: `url(${backgroundImg || theme4Vars[0].image})`,
      }}
      loading="lazy"
    >
      <div className={theme4Styles.wrapper}>
        <div className={theme4Styles.bannerTextC}>
          <div className={theme4Styles.bannerText}>
            <h2 className={theme4Styles.bannerTl}>{mainTitle}</h2>
            <form onSubmit={handleSubmit}>
              {(() => {
                if (display_search_bar) {
                  return (
                    <div className={theme4Styles.banSearchBox}>
                      <input
                        type="text"
                        className={theme4Styles.searchField}
                        placeholder={placeholder}
                        {...bindKeyword}
                      />
                      <button className={theme4Styles.searchBtn}>
                        <img
                          alt={translateStatic("search", locale, defaultLocale)}
                          src={searchIcon}
                        />
                      </button>
                    </div>
                  );
                }
              })()}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
