import React, { useContext } from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";

import { useInput } from "../../../hooks/input-hook";
import SiteMetaContext from "../../../contexts/SiteMetaContext";
import {
  theme2Vars,
  templateVariableValue,
} from "../../../helpers/templateVariables";
import { translateStatic } from "../../../helpers/translationHelper";
import * as theme2Styles from "../scss/theme2.module.scss";

const Banner = () => {
  // eslint-disable-next-line no-unused-vars
  const { value: keyword, bind: bindKeyword, reset: resetKeyword } = useInput(
    ""
  );

  const siteData = useContext(SiteMetaContext);
  const {
    remarso: {
      domainByURL: {
        website: {
          setting: display_settings,
          templateConfiguration,
          template: { templateVariables },
        },
      },
    },
    currentLocaleKey: locale,
    defaultLocaleKey: defaultLocale,
  } = siteData;

  const handleSubmit = (evt) => {
    evt.preventDefault();
    navigate(`/${locale}/properties?search=${keyword}`);
  };

  const { display_search_bar } = display_settings;

  const homeTitle = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-main-title",
    locale,
    defaultLocale
  );
  const homeText = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-main-text",
    locale,
    defaultLocale
  );
  const backgroundImg = templateVariableValue(
    templateConfiguration,
    templateVariables,
    theme2Vars[0].slug,
    locale,
    defaultLocale
  );
  let placeholder = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-search-placeholder",
    locale,
    defaultLocale
  );
  placeholder = placeholder || translateStatic("banner_search_text", locale, defaultLocale);

  return (
    <section
      className={theme2Styles.banner}
      style={{
        backgroundImage: `url(${backgroundImg || theme2Vars[0].image})`,
      }}
      loading="lazy"
    >
      <div className={theme2Styles.wrapper}>
        <div className={theme2Styles.bannerTextC}>
          <div className={theme2Styles.bannerText}>
            <h2 className={theme2Styles.bannerTl}>{homeTitle}</h2>
            <p>{homeText}</p>
            <form onSubmit={handleSubmit}>
              {(() => {
                if (display_search_bar) {
                  return (
                    <div className={theme2Styles.banSearchBox}>
                      <input
                        type="text"
                        className={theme2Styles.searchField}
                        placeholder={placeholder}
                        {...bindKeyword}
                      />
                      <button
                        className={`${theme2Styles.searchBtn}`}
                      >
                        {translateStatic("search", locale, defaultLocale)}
                      </button>
                    </div>
                  );
                }
              })()}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

Banner.propTypes = {
  backgroundImg: PropTypes.string,
  locale: PropTypes.string,
};

export default Banner;
