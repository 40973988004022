import React from "react";
import PropTypes from "prop-types";
import * as theme5Styles from "../scss/theme5.module.scss";

// import image1 from "../images/image1.png";

const Welcome = ({ title, text }) => {
  if (title || text) {
    return (
      <div className={`${theme5Styles.welcomeC}`}>
        <div className={`${theme5Styles.welcomeSec}`}>
          <div className={`${theme5Styles.welcomeBox}`}>
            <div className={`${theme5Styles.description}`}>
              <h2>{title}</h2>
              <p>{text}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return "";
};

Welcome.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
};

export default Welcome;
