/* eslint react/prop-types: 0 */
import React from "react";

import Layout from "./components/layout";
import SEO from "./components/seo";
import Banner from "./components/banner";
import Welcome from "./components/welcome";
import PropertyList from "./components/property-list";
import BuildingList from "./components/building-list";
import {
  theme3Vars,
  templateVariableValue,
} from "../../helpers/templateVariables";

import * as theme3Styles from "../theme3/scss/theme3.module.scss";

const Theme3 = ({ result, pageContext }) => {
  const {
    remarso: {
      domainByURL: {
        website: {
          templateConfiguration,
          template: { templateVariables },
          setting: display_settings,
          list
        },
      },
    },
    currentLocaleKey: locale,
    defaultLocaleKey: defaultLocale,
  } = result;

  const { 
    display_listings, 
    display_projects, 
    home_listings_display_type, 
    home_projects_display_type
  } = display_settings;

  const mainTitle = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-main-title",
    locale,
    defaultLocale
  );

  const section1 = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-1-text",
    locale,
    defaultLocale
  );
  const section1Title = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-1-title",
    locale,
    defaultLocale
  );
  const section1Logo = templateVariableValue(
    templateConfiguration,
    templateVariables,
    theme3Vars[1].slug,
    locale,
    defaultLocale
  );

  const section2 = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-2-text",
    locale,
    defaultLocale
  );
  const section2Title = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-2-title",
    locale,
    defaultLocale
  );
  const section2Logo = templateVariableValue(
    templateConfiguration,
    templateVariables,
    theme3Vars[2].slug,
    locale,
    defaultLocale
  );

  const section3 = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-3-text",
    locale,
    defaultLocale
  );
  const section3Title = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-section-3-title",
    locale,
    defaultLocale
  );
  const section3Logo = templateVariableValue(
    templateConfiguration,
    templateVariables,
    theme3Vars[3].slug,
    locale,
    defaultLocale
  );

  const metaTitle = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "meta-title-home",
    locale,
    defaultLocale
  );

  const listingTitle = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-listing-title",
    locale,
    defaultLocale
  );
  const listingCatchline = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-listing-catchline",
    locale,
    defaultLocale
  );

  const buildingTitle = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-building-title",
    locale,
    defaultLocale
  );
  const buildingCatchline = templateVariableValue(
    templateConfiguration,
    templateVariables,
    "home-building-catchline",
    locale,
    defaultLocale
  );

  const { properties, buildings } = pageContext;

  return (
    <Layout>
      <SEO metaTitle={metaTitle} />

      <Banner />

      {(() => {
        if (
          mainTitle ||
          section1 ||
          section1Title ||
          section1Logo ||
          section2 ||
          section2Title ||
          section2Logo ||
          section3 ||
          section3Title ||
          section3Logo
        ) {
          return (
            <div
              className={`${theme3Styles.homeContainetArea} ${theme3Styles.grey}`}
            >
              <section
                className={`${theme3Styles.homeContainet}`}
              >
                <div
                  className={`${[
                    theme3Styles.wrapper,
                  ].join(" ")}`}
                >
                  {mainTitle && (
                    <h2 className={`${theme3Styles.secTl} font-black`}>
                      {mainTitle}
                    </h2>
                  )}
                  <div className={theme3Styles.welRow}>
                    {(section1Title || section1 || section1Logo) && (
                      <div className={theme3Styles.colm}>
                        <Welcome
                          title={section1Title}
                          text={section1}
                          logo={section1Logo}
                        />
                      </div>
                    )}
                    {(section2Title || section2 || section2Logo) && (
                      <div className={theme3Styles.colm}>
                        <Welcome
                          title={section2Title}
                          text={section2}
                          logo={section2Logo}
                        />
                      </div>
                    )}
                    {(section3Title || section3 || section3Logo) && (
                      <div className={theme3Styles.colm}>
                        <Welcome
                          title={section3Title}
                          text={section3}
                          logo={section3Logo}
                        />
                      </div>
                    )}
                    
                </div>
                  </div>
              </section>
                  
              {(() => {
                if (
                  display_listings && 
                  (list.listing_type == 0 || list.listing_type == 1) && 
                  properties.length > 0
                ) {
                  return (
                    <section className={theme3Styles.homeContainet}>
                      <div className={theme3Styles.wrapper}>
                        <h2 className={`${theme3Styles.secTl}`}>
                          {listingTitle}
                          <span>{listingCatchline}</span>
                        </h2>
                        <PropertyList
                          properties={properties}
                          locale={locale}
                          defaultLocale={defaultLocale}
                          is_carousel={home_listings_display_type === 'carousel'}
                        />
                      </div>
                    </section>
                  );
                }
              })()}

              {(() => {
                if (
                  display_projects && 
                  (list.listing_type == 0 || list.listing_type == 2) && 
                  buildings.length > 0
                ) {
                  return (
                    <section className={theme3Styles.homeContainet}>
                      <div className={theme3Styles.wrapper}>
                        <h2 className={`${theme3Styles.secTl}`}>
                          {buildingTitle}
                          <span>{buildingCatchline}</span>
                        </h2>
                        <BuildingList
                          buildings={buildings}
                          locale={locale}
                          defaultLocale={defaultLocale}
                          is_carousel={home_projects_display_type === 'carousel'}
                        />
                      </div>
                    </section>
                  );
                }
              })()}
            </div>
          );
        }
      })()}
    </Layout>
  );
};

export default Theme3;
